<template>
  <div class="page" v-loadingx="loading">
    <Title :title="title || (yearNum + '年采购方式申请明细')">
      <div class="b-detail-t-s">
        <fm-date-picker style="width: 7rem;" @change="yearChange" v-model="year" type="year" placeholder="请选择年份"></fm-date-picker>
      </div>
    </Title>
    <div class="count">
      <div class="c-item" :class="{'c-item-nb': index === countData.length - 1}" v-for="(item, index) in countData" :key="index">
        <div class="t">{{item.title}}</div>
        <div class="num">
          <Money :money="item.value"></Money>
          <div class="img-d">
            <img :src="item.src" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="search">
        <div class="search-item">
          <div class="label">是否已采购</div>
          <div class="form">
            <fm-select :clearable="true" v-model="search.isUsedText">
              <fm-option v-for="item in ['已采购', '未采购']" :key="item" :value="item" :label="item"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">类型</div>
          <div class="form">
            <fm-select :clearable="true" v-model="search.type">
              <fm-option v-for="item in typeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">采购方式</div>
          <div class="form">
            <fm-select :clearable="true" v-model="search.method">
              <fm-option v-for="item in ['询价', '单一来源采购', '竞争性谈判', '公开招标', '邀请招标']" :key="item" :value="item" :label="item"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">职能科室</div>
          <div class="form">
            <fm-select :clearable="true" v-model="search.busOrgId">
              <fm-option v-for="item in busOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">申报部门</div>
          <div class="form">
            <fm-select :clearable="true" v-model="search.submitOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">分管领导</div>
          <div class="form">
            <fm-select :clearable="true" v-model="search.purchaseMethodLeadUserId">
              <fm-option v-for="item in purchaseMethodLeadUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">状态</div>
          <div class="form">
            <fm-select clearable v-model="search.status">
              <fm-option v-for="item in statusList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div>
        <div class="search-item" style="margin-right: 0px;">
          <fm-btn @click="output">批量导出</fm-btn>
        </div>
      </div>
      <div class="table">
        <fm-table-new
          :simple-filter="false"
          :columns="columns"
          :auto-height="true"
          border="row"
          :export-file-name="title || (yearNum + '年采购明细')"
          ref="table"
          :data-list="showDetail"
          :stripe="true"
          emptyPlaceholder="-">
        </fm-table-new>
      </div>
    </div>
  </div>
</template>

<script>
import { purchaseMethodBatchDetailsRequest } from '../../api'

import Title from './components/cmp/title'
import Money from './components/cmp/money'
import { defaultPurchaseMethod } from './lib';

export default {
  components: {
    Title,
    Money
  },
  activated () {
    if (this.$route.query.id || this.$route.query.year) {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.year = null
        this.title = this.$route.query.title
      }
      if (this.$route.query.year) {
        this.year = new Date(this.$route.query.year + '-02-01')
      }
    } else {
      this.year = new Date()
      this.id = null
      this.title = null
    }
    this.loadData()
  },
  data () {
    return {
      title: null,
      year: new Date(),
      id: null,
      orgList: [],
      purchaseMethodLeadUserList: [],
      busOrgList: [],
      typeList: [{key: 'mini', label: '金额2000元以下'}, {key: 'small', label: '金额2000元以上'}, {key: 'big', label: '大型采购'}],
      search: {
        isUsedText: null,
        orgId: null,
        leadUserId: null,
        busOrgId: null,
        purchaseLeadUserId: null,
        status: null
      },
      detail: [],
      loading: false,
      statusList: []
    }
  },
  computed: {
    yearNum () {
      return this.year ? this.year.getFullYear() : null
    },
    columns () {
      return [{
        field: 'isUsedText',
        title: '是否已采购'
      },
      {
        field: 'typeText',
        width: 130,
        title: '类型'
      },
      {
        field: 'method',
        title: '采购方式'
      },
      {
        field: 'goodsNames',
        width: 200,
        title: '项目名称'
      },
      {
        field: 'price',
        title: '采购方式单价'
      },
      {
        field: 'num',
        title: '采购方式数量'
      },
      {
        field: 'total',
        title: '采购方式总价'
      },
      {
        field: 'busOrgName',
        title: '职能科室'
      },
      {
        field: 'submitOrgName',
        title: '申报部门'
      },
      {
        field: 'purchaseMethodLeadUserName',
        title: '分管领导'
      },
      {
        field: 'statusText',
        width: 200,
        title: '状态',
        fixed: 'right'
      },
      {
        field: 'action',
        title: '操作',
        fixed: 'right',
        render: (h, row) => {
          return h('fm-btn', {
            on: {
              click: () => this.print(row)
            }
          }, '打印')
        }
      }]
    },
    showDetail () {
      let allData = this.detail
      Object.keys(this.search).forEach(key => {
        if (this.search[key]) {
          allData = allData.filter(v => v[key] === this.search[key])
        }
      })
      return allData
    },
    countData () {
      let count = {
        total: 0,
        usedTotal: 0
      }
      this.showDetail.forEach(v => {
        count.total += v.total
        if (v.isUsed) {
          count.usedTotal += v.total
        }
      })
      let data = [{
        title: '总采购方式申请金额',
        value: count.total,
        src: '/static/images/budget/l1.png'
      },
      {
        title: '已采购金额',
        value: count.usedTotal,
        src: '/static/images/budget/l22.png'
      },
      {
        title: '未采购金额',
        value: count.total - count.usedTotal,
        src: '/static/images/budget/l3.png'
      }]
      return data
    }
  },
  methods: {
    async print (row) {
      this.loading = true
      const param = { statusActionRecord: 1, id: row.id }
      let data = null
      if (this.$authFunsProxy.get) {
        data = await purchaseMethodBatchDetailsRequest.get(param)
      } else if (this.$authFunsProxy.getByAuth) {
        data = await purchaseMethodBatchDetailsRequest.getByAuth(param)
      }
      if (!data || !data.length) {
        this.loading = false
        return this.$notice.error('数据不存在')
      }
      console.log(data[0])
      defaultPurchaseMethod(data[0])
      this.loading = false
    },
    output () {
      this.$refs.table.openExport()
    },
    yearChange () {
      if (this.year) {
        this.search.status = null
        this.id = null
        this.title = null
        this.loadData()
      }
    },
    async loadData () {
      this.detail = []
      let parm = {}
      if (this.yearNum) {
        parm.year = this.yearNum
      }
      if (this.id) {
        parm.budgetYearId = this.id
      }
      this.loading = true
      this.detail = []
      if (this.$authFunsProxy.get) {
        this.detail = await purchaseMethodBatchDetailsRequest.getInfo(parm)
      } else if (this.$authFunsProxy.getByAuth) {
        this.detail = await purchaseMethodBatchDetailsRequest.getInfoByAuth(parm)
      }

      this.orgList = []
      this.purchaseMethodLeadUserList = []
      this.busOrgList = []
      let statusList = {}
      this.detail.forEach(v => {
        statusList[v.status] = v.statusText
        if (v.submitOrgId && !this.orgList.find(v1 => v1.key === v.submitOrgId)) {
          this.orgList.push({
            key: v.submitOrgId,
            label: v.submitOrgName
          })
        }
        if (v.purchaseMethodLeadUserId && !this.purchaseMethodLeadUserList.find(v1 => v1.key === v.purchaseMethodLeadUserId)) {
          this.purchaseMethodLeadUserList.push({
            key: v.purchaseMethodLeadUserId,
            label: v.purchaseMethodLeadUserName
          })
        }
        if (v.busOrgId && !this.busOrgList.find(v1 => v1.key === v.busOrgId)) {
          this.busOrgList.push({
            key: v.busOrgId,
            label: v.busOrgName
          })
        }
        v.isUsedText = v.isUsed ? '已采购' : '未采购'
        v.typeText = (this.typeList.find(v1 => v1.key === v.type) || {label: ''}).label
        v.goodsNames = v.goodsNameList.join(',')
      })
      this.statusList = Object.keys(statusList).map(v => {
        return { label: statusList[v], key: v }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #EEF3FA;
  padding: 0 16px 12px;
  height: 100%;
  .b-detail-t-s {
    display: flex;
    height: 100%;
    margin-left: 20px;
    align-items: flex-end;
  }
  .count {
    height: 118px;
    display: flex;
    padding: 26px 0;
    background: #fff;
    border-radius: 6px;
    margin-top: 14px;
    justify-content: center;
  }
  .c-item {
    width: 33%;
    padding-left: 64px;
    border-right: 1px solid #D8D8D8;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .t {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
  .c-item-nb {
    border-right: 0px solid #D8D8D8;
  }
  .num {
    position: relative;
    .img-d {
      width: 61px;
      height: 16px;
      position: absolute;
      right: 23%;
      top: 0;
      img {
        width: 100%;
        height: 100%;
      }

    }
  }
  .detail {
    margin-top: 14px;
    flex: 1;
    background: #FFFFFF;
    border-radius: 6px;
    .search {
      margin: 20px;
      display: flex;
      justify-content: space-between;
      .search-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        .label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .table {
      height: calc(100% - 92px);
      padding: 0 20px 20px;
      width: 100%;
    }
  }
}
</style>

<style lang="less">
.page {
  .b-detail-t-s {
    input {
      background: rgba(0,0,0,0) !important;
    }
  }
}
</style>